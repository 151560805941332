/**
 * This file contains all the endpoints used in the application.
 * The endpoints are grouped by the resource they are related to (mostly).
 *
 * The endpoint suffixed by "_INDEXED" mark that the endpoint is not querying
 * the DB directly, but an search indexing service instead.
 */

const APPLICATIONS_BASE = "/api/int/applications";
const ATTRIBUTES_BASE = "/api/int/attributes";
const CLIENTS_BASE = "/api/int/clients";
const CONTRACTS_BASE = "/api/int/docusign_placeholders";
const CONTRACT_TEMPLATES = "/api/int/contract_templates";
const BUSINESSES_BASE = "/api/int/business_profiles";
const PROFILES_BASE = "/api/int/profiles";
const PROFILES_BASE_V2 = "/api/v2/profiles";
const OFFERS_BASE = "/api/int/offers";
const FUNDERS_BASE = "/api/int/funders";
const USERS_BASE = "/api/int/users";
const DATA_ORCHESTRATION_BASE = "/api/int/data_orchestration_templates";
const WORKFLOW_BASE = "/api/int/workflow_templates";
const SCORECARDS_BASE = "api/int/score_cards";
const SCORECARD_GROUPS_BASE = "api/int/score_card_groups";
const FILTERS_BASE = "/api/int/filters";
const AUTH_BASE = "/api/int/auth";
const BASE = "/api/int";
const APPLICATION_ATTRIBUTES_BASE = `${APPLICATIONS_BASE}/%s/attributes`;
const BANK_ACCOUNTS_BASE = `${APPLICATIONS_BASE}/%s/bank_accounts`;
const PRESETS_BASE = "/api/int/presets";
const BATCH_ACTIONS_BASE = "/api/int/batch_actions";
const EMAIL_TEMPLATES_BASE = "/api/int/email/template";
const SMS_TEMPLATES_BASE = "/api/int/sms/template";
const OPTIONS_BASE = "/api/int/options";
const COMMUNICATION_LOGS_BASE = "/api/int/communication_logs";
const EMAIL_BASE = "/api/int/communication/emails";
const SMS_BASE = "/api/int/communication/sms";
const MY_BASE = "/api/int/my";
const SUBMISSION_PARTNER_BASE = "/api/int/submission_partners";
const COMMUNICATION_BASE = "/api/int/communication";

const endpoints = {
  REFRESH_TOKEN: "/api/int/auth/refresh",
  AUTH_DETAILS: "/api/int/auth/me",
  IMPERSONATE_USER: "/api/int/users/%s/impersonate",
  DOC_VERIFICATION: "/api/v1/applications/business_credit/%s/verify-documents",

  CSRF_TOKEN: "/api/csrf-token",

  LOGIN: "/api/auth/login",
  REGISTER: "/api/auth/register",
  SEND_RESET_LINK: "/api/password/email",
  RESET_PASSWORD: "/api/password/reset",
  RENEW_PASSWORD: "/api/password/renew",

  GET_OPTIONS: {
    ACTIVITY_HUB: {
      TABS: {
        ALL: `${OPTIONS_BASE}/activity_hub`,
        DEAL: `${OPTIONS_BASE}/activity_hub/%s`
      }
    },
    ALL: `${OPTIONS_BASE}`,
    APPLICATION: `${OPTIONS_BASE}/application`,
    ATTRIBUTE: `${OPTIONS_BASE}/attributes`,
    CONSENT_TEMPLATES: `${BASE}/options/consent_templates`,
    DYNAMIC_FIELDS: `${OPTIONS_BASE}/dynamic_fields`,
    WORKFLOW_TEMPLATES: `${OPTIONS_BASE}/workflow_templates`,
    CONTRACT_TEMPLATES: `${OPTIONS_BASE}/contract_templates`,
    SINGLE_ATTRIBUTE_OPTIONS: "api/int/expressions/attributes/%s"
  },

  FILTERS: {
    ALL: FILTERS_BASE,
    CREATE: FILTERS_BASE,
    SINGLE: `${FILTERS_BASE}/%s`,
    VISIBILITY: `${FILTERS_BASE}/visibility`,
    SORT: `${FILTERS_BASE}/sort`
  },

  APPLICATIONS: {
    GET_APPLICATIONS: `${APPLICATIONS_BASE}`,
    GET_APPLICATIONS_INDEXED: `${APPLICATIONS_BASE}/filter`,
    DUPLCATE: `${APPLICATIONS_BASE}/%s/duplicate`,
    GET_APPLICATIONS_BUSINESS_CREDIT: `${APPLICATIONS_BASE}/business_credit`,
    SINGLE_APPLICATION: `${APPLICATIONS_BASE}/%s`,
    UPDATE_APP_INFO: `${APPLICATIONS_BASE}/%s`,
    UPDATE_BUSINESS_INFO: `${APPLICATIONS_BASE}/%s/business_info`,
    UPDATE_PERSONAL_INFO: `${APPLICATIONS_BASE}/%s/personal_info/%s`,
    DEAD: `${APPLICATIONS_BASE}/%s/dead`,
    SEND_DEAD_EMAIL: `${APPLICATIONS_BASE}/%s/declined_notification`,
    REVIVE: `${APPLICATIONS_BASE}/%s/revive`,
    UPDATE_STATUS: `${APPLICATIONS_BASE}/%s/status`,
    PLACEMENTS: `${APPLICATIONS_BASE}/%s/placements`,
    PLACEMENTS_FOR_DEALS: `api/int/placements`,
    OFFERS_FOR_DEALS: `api/int/offers`,
    CONTACTED_VIA: `${APPLICATIONS_BASE}/%s/offer_contacted_via`,
    PLACEMENT_STATUS: `${APPLICATIONS_BASE}/%s/placements/status`,
    FILES: {
      SINGLE: `${APPLICATIONS_BASE}/%s/files`,
      MULTIPLE: `${APPLICATIONS_BASE}/%s/files/multiple`,
      REQUIRED: `${APPLICATIONS_BASE}/%s/required_documents`
    },
    APP_OFFERS: `${APPLICATIONS_BASE}/%s/offers`,
    UPDATE_OFFERS: `${APPLICATIONS_BASE}/%s/offers/%s`,
    COUNT: `${APPLICATIONS_BASE}/count`,
    DISCOVERY_CALL_QUESTION: `${APPLICATIONS_BASE}/%s/questions`,
    UPDATE_DISCOVERY_CALL_QUESTION: `${APPLICATIONS_BASE}/%s/questions/%s`,
    UPDATE_DISCOVERY_CALL_ANSWERS: `${APPLICATIONS_BASE}/%s/questions/answer`,
    UPDATE_CLOSING_STATUS: `${APPLICATIONS_BASE}/%s/closing/status`,
    COMMERCIAL_DATA: `${APPLICATIONS_BASE}/%s/commercial_data`,
    SEND_CONTRACT_BY_EMAIL: `${APPLICATIONS_BASE}/%s/send_contract`,
    SEND_FUNDING_CONTRACT: `${APPLICATIONS_BASE}/%s/funding_contract`,
    GENERATE_BANK_DOCUMENT_REPORTS: `${APPLICATIONS_BASE}/%s/bank_report`,
    GET_LINK: `${APPLICATIONS_BASE}/%s/links`,
    GENERATE_TEMPORARY_LINKS: `${APPLICATIONS_BASE}/%s/temporary_links`,
    ENRICH_APP: "/api/int/applications/%s/enrich",
    ENRICH_BUSINESS_CREDIT_APP:
      "/api/v1/applications/business_credit/%s/enrich",
    BUSINESS_CREDIT_STATISTICS_BY_SERVICE:
      "/api/int/business_credit/statistics/by-service",
    BUSINESS_CREDIT_STATISTICS_BY_DATE:
      "/api/int/business_credit/statistics/by-date",
    ADVANCED_OCROLUS_CFA: `${APPLICATIONS_BASE}/%s/cfa/ocrolus/%s/analytics`,
    MONEYTHUMB_ANALYTICS: `${APPLICATIONS_BASE}/%s/cfa/moneythumb/%s/analytics`,
    CLIENT_PREFERENCES: "api/int/clients/%s/preferences",
    UPDATE_PIIS: `${APPLICATIONS_BASE}/%s/piis`,
    EQUIPMENT_RENTAL_APPROVAL: `${APPLICATIONS_BASE}/%s/equipment_rental_approval`,
    APPLICATION_NOTES: `${APPLICATIONS_BASE}/%s/notes`,
    APPLICATION_ATTRIBUTES: APPLICATION_ATTRIBUTES_BASE,
    APPLICATION_ATTRIBUTES_ID: `${APPLICATION_ATTRIBUTES_BASE}/%s`,
    MODIFY_APPLICATION_NOTES: `${APPLICATIONS_BASE}/%s/notes/%s`,
    METRICS: `${APPLICATIONS_BASE}/%s/metrics`,
    MARK_NOTES_AS_READ: `${APPLICATIONS_BASE}/%s/mark_notes_as_read`,
    DATA_ORCHESTRATION_BATCH_EXECUTE: `${BATCH_ACTIONS_BASE}/applications/data_orchestration/execute`,
    DATA_ORCHESTRATION_EXECUTE: `${APPLICATIONS_BASE}/%s/data_orchestration/execute`,
    DATA_ORCHESTRATION_PASS_RESUME: `${APPLICATIONS_BASE}/%s/data_orchestration/%s/continue_pass`,
    DATA_ORCHESTRATION_FAIL_RESUME: `${APPLICATIONS_BASE}/%s/data_orchestration/%s/continue_fail`,
    DATA_ORCHESTRATION_RETRY_STEP: `${APPLICATIONS_BASE}/%s/data_orchestration/%s/continue_retry`,
    DATA_ORCHESTRATION_MASTER_STATUS: `${APPLICATIONS_BASE}/%s/data_orchestration/%s/master_status`,
    SINGLE_DATA_ORCHESTRATION_LOG: `${APPLICATIONS_BASE}/%s/data_orchestration_logs/%s`,
    DATA_ORCHESTRATION_LOGS: `${APPLICATIONS_BASE}/%s/data_orchestration_logs`,
    BUSINESS_NOTES: `${APPLICATIONS_BASE}/%s/business_notes`,
    MODIFY_BUSINESS_NOTES: `${APPLICATIONS_BASE}/%s/business_notes/%s`,
    DEAL_MODE: `${APPLICATIONS_BASE}/%s/deal_mode`,
    UPDATE_APPLICATION_STIPS: `${APPLICATIONS_BASE}/%s/stips`,
    STIPS_COLLECTION_LINK: `${APPLICATIONS_BASE}/%s/stips_collection_link`,
    BANK_TRANSACTIONS: `${APPLICATIONS_BASE}/%s/bank_transactions`,
    UNDERWRITING_LOGS: `${APPLICATIONS_BASE}/%s/underwriting_logs`,
    DATA_ORCHESTRATION_TEMPLATES: `${APPLICATIONS_BASE}/%s/data_orchestration_templates`,
    WIDGET_INFO: `${APPLICATIONS_BASE}/%s/widget_info`,
    PLAID_DATA: `${APPLICATIONS_BASE}/%s/plaid_data`,
    BANK_ACCOUNTS: {
      GET_BANK_ACCOUNTS: `${BANK_ACCOUNTS_BASE}`,
      DOWNLOAD: `${BANK_ACCOUNTS_BASE}/%s/download`,
      UPDATE_VISIBILITY: `${BANK_ACCOUNTS_BASE}/visibility`
    },
    SEND_EMAIL: `${APPLICATIONS_BASE}/%s/outreach-webhook`,
    SCORECARD_RESULTS: `${APPLICATIONS_BASE}/%s/score_cards/results`,
    APPLICATION_FILES: `${APPLICATIONS_BASE}/%s/files`,
    UPDATE_SERVICE_JSON: "/internal/applications/%s/update_enrichment_response",
    SERVICES_OPTIONS: "/api/int/services_options",
    VALIDATE_PHONES: `${APPLICATIONS_BASE}/%s/validate_numbers`
  },

  AUTH: {
    ACL: `${AUTH_BASE}/acl`,
    ABILITY: `${AUTH_BASE}/can`,
    SETTINGS: `/api/auth/settings`
  },

  ATTRIBUTES: {
    BASE: `${ATTRIBUTES_BASE}`,
    SINGLE: `${ATTRIBUTES_BASE}/%s`,
    MANY: `${ATTRIBUTES_BASE}/many`,
    SERVICES: `${ATTRIBUTES_BASE}/services`,
    EVALUATE: `${ATTRIBUTES_BASE}/evaluate`
  },

  CONTRACTS: {
    BASE: `${CONTRACTS_BASE}`,
    SINGLE: `${CONTRACTS_BASE}/%s`
  },

  CONTRACT_TEMPLATES: {
    BASE: CONTRACT_TEMPLATES,
    SINGLE: `${CONTRACT_TEMPLATES}/%s`,
    DUPLICATE: `${CONTRACT_TEMPLATES}/%s/duplicate`,
    UPLOAD_DOCUMENT: `${CONTRACT_TEMPLATES}/%s/upload_document`,
    DELETE_DOCUMENT: `${CONTRACT_TEMPLATES}/%s/document/%s`
  },

  OFFERS: {
    SINGLE_OFFER: `${OFFERS_BASE}/%s`,
    UPDATE_OFFER_STIPS: `${OFFERS_BASE}/%s/stips`,
    UPDATE_OFFER_STATUS: `${OFFERS_BASE}/%s/status`,
    SEND_STIPS_TO_FUNDER: `${OFFERS_BASE}/%s/send_stips_to_funder`,
    CONFIRM_OFFER: `${OFFERS_BASE}/%s/confirm`,
    DELETE_OFFER: `${APPLICATIONS_BASE}/%s/offers/%s`,
    CLIENT_FUNDED_OFFER: `${APPLICATIONS_BASE}/%s/client_funded_offer`,
    SEND_OFFERS_EMAIL: `${APPLICATIONS_BASE}/%s/offers/email_applicant`,
    CREATE_EQUIPMENT_RENTAL_OFFER: `${APPLICATIONS_BASE}/%s/equipment_rental_offer`,
    UPDATE_EQUIPMENT_RENTAL_OFFER: `${APPLICATIONS_BASE}/%s/equipment_rental_offer/%s`,
    FUNDING_TRANSACTIONS: `${OFFERS_BASE}/%s/funding_transactions`,
    SINGLE_FUNDING_TRANSACTION: `${OFFERS_BASE}/%s/funding_transactions/%s`,
    UPLOAD_TRANSACTION_FILE: `${OFFERS_BASE}/%s/funding_transactions/%s/upload`,
    STIPS_COLLECTION_LINK: `${OFFERS_BASE}/%s/stips_collection_link`,
    SEND_TO_LOAN_PROVIDER: `${OFFERS_BASE}/%s/loan_providers/%s/merchant`,
    OFFER_NOTE: `${OFFERS_BASE}/%s/note`,
    TOGGLE_SHOW_OFFER: `${OFFERS_BASE}/%s/toggle_shared`
  },

  BUSINESSES: {
    ALL: BUSINESSES_BASE,
    SINGLE: `${BUSINESSES_BASE}/%s`,
    APPLICATIONS: `${BUSINESSES_BASE}/%s/applications`,
    LAST_APPLICATION: `${BUSINESSES_BASE}/%s/last_application`,
    BUSINESS_RELATIONS: `${BUSINESSES_BASE}`
  },

  PROFILES: {
    CREATE_PROFILE: PROFILES_BASE_V2,
    ALL_BY_TYPE: `${PROFILES_BASE}/%s`,
    DEALS: `${PROFILES_BASE}/%s/%s/applications`,
    ENTITY_OWNERSHIP_SNAPSHOT: `${PROFILES_BASE}/%s/%s/ownership`,
    LATEST_ENTITY_OWNERSHIP_SNAPSHOT: `${PROFILES_BASE}/%s/%s/ownership/latest`,
    RELATIONS: `${PROFILES_BASE}/%s/%s/relationships`,
    SINGLE_BY_TYPE: `${PROFILES_BASE}/%s/%s`
  },

  EVALUATE: {
    EXPRESSIONS: `${BASE}/expressions/evaluate`
  },

  FILES: {
    OFFER_STIP: "/api/int/files/%s/offer_stip",
    SINGLE: "/api/int/files/%s",
    ALL: "/api/int/files",
    UPDATE: "/api/int/applications/%s/files/%s"
  },

  FUNDERS: {
    CREATE_FUNDER: FUNDERS_BASE,
    UPDATE_FUNDER: `${FUNDERS_BASE}/%s`,
    SINGLE_FUNDER: `${FUNDERS_BASE}/%s`,
    GET_FUNDERS: FUNDERS_BASE,
    APPROVE_FUNDER: `${FUNDERS_BASE}/%s/approve`,
    GET_FUNDER_STATISTICS: `${FUNDERS_BASE}/%s/statistics`,
    GET_USERS: `${FUNDERS_BASE}/%s/users`,
    LOGO: `${FUNDERS_BASE}/%s/logo`
  },

  CLIENTS: {
    ALL: `${CLIENTS_BASE}`,
    SINGLE: `${CLIENTS_BASE}/%s`,
    UPDATE_CLIENT_PIIS: `${CLIENTS_BASE}/%s/piis`,
    GET_PLAID_LINK_TOKEN: `${CLIENTS_BASE}/%s/plaid/link-token`,
    SET_PLAID_TOKEN: `${CLIENTS_BASE}/%s/plaid/token`,
    UPLOAD_FILES: `${CLIENTS_BASE}/%s/files`,
    CLIENT_USERS: `${CLIENTS_BASE}/%s/users`,
    NOTIFICATIONS: `${CLIENTS_BASE}/%s/notifications`,
    WIDGET_LOGO: `${CLIENTS_BASE}/%s/widget_logo`,
    INTEGRATION: `${CLIENTS_BASE}/%s/integration`,
    INTEGRATION_BORROWER_PLATFORM: `${CLIENTS_BASE}/%s/integration/borrower_platform`,
    INTEGRATION_WIDGET: `${CLIENTS_BASE}/%s/integration/widget`,
    EXPERIAN_SUBCODES: `${CLIENTS_BASE}/%s/experian_subcodes`,
    CREDENTIALS: `${CLIENTS_BASE}/%s/service_credentials`,
    CREDENTIALS_RULES: `${CLIENTS_BASE}/%s/service_credentials_rules`,
    CREDENTIALS_DOCUSIGN_CONSENT: `${CLIENTS_BASE}/%s/integration/docusign_consent`,
    FUNDER_INTEGRATIONS: `${CLIENTS_BASE}/%s/integration/funders`,
    FUNDER_INTEGRATION_SINGLE: `${CLIENTS_BASE}/%s/integration/funders/%s`,
    UPDATE_CLOSE_IO: `${CLIENTS_BASE}/%s/closeio`,
    EMAIL: {
      BRANDING: {
        SET_ACTIVE_TEMPLATE: `${CLIENTS_BASE}/%s/email_customization/%s/active`,
        CUSTOMISATION_DETAILS: `${CLIENTS_BASE}/%s/email_customization/%s`,
        IMAGE: `${CLIENTS_BASE}/%s/email_customization/%s/upload`,
        EMAIL_TEMPLATES: `${CLIENTS_BASE}/%s/email_customization`,
        HEADER_IMAGE: `${CLIENTS_BASE}/%s/email_customization/%s/header_image`,
        LOGO: `${CLIENTS_BASE}/%s/email_customization/%s/logo`
      },
      RECIPIENTS: `${CLIENTS_BASE}/%s/recipients`
    },
    SALES_REPRESENTATIVES: `${CLIENTS_BASE}/%s/sales_representatives`,
    SINGLE_SALES_REPRESENTATIVE: `${CLIENTS_BASE}/%s/sales_representatives/%s`,
    WHITELABEL: `${CLIENTS_BASE}/%s/whitelabel`,
    WHITELABEL_SINGLE: `${CLIENTS_BASE}/%s/whitelabel/%s`,
    DNS_RECORDS: `${CLIENTS_BASE}/%s/whitelabel/%s/dns_records`,
    DOMAIN_STATUS: `${CLIENTS_BASE}/%s/whitelabel/%s/domain_status`,
    WIDGET_TEMPLATES: `${CLIENTS_BASE}/%s/widget_templates`,
    WIDGET_TEMPLATE: `${CLIENTS_BASE}/%s/widget_templates/%s`,
    WIDGET_TEMPLATE_LOGO: `${CLIENTS_BASE}/%s/widget_templates/%s/logo`,
    TEST_EMAIL: `${CLIENTS_BASE}/%s/test_aec`,
    FUNDER_LINK: `${CLIENTS_BASE}/%s/link_to_funder`,
    TEST_MODE: `${CLIENTS_BASE}/%s/test_mode`,
    MASKED_PIIS: `${CLIENTS_BASE}/%s/masked_piis`,
    EMBED_REPORT_TOKEN: `${CLIENTS_BASE}/%s/embed_report_token`,
    TRACKING_TOKENS: `${BASE}/tracking_tokens`,
    PIIS: `${CLIENTS_BASE}/%s/piis/%s/deobfuscate`,
    ADDITIONAL_TRACKING_TOKENS: `${BASE}/additional_tracking_tokens`,
    WHITELABEL_ALIASES: {
      ALL: `${CLIENTS_BASE}/%s/whitelabel/%s/aliases`,
      SINGLE: `${CLIENTS_BASE}/%s/whitelabel/%s/aliases/%s`
    }
  },

  USERS: {
    ALL: USERS_BASE,
    SINGLE: `${USERS_BASE}/%s`,
    EMPLOYEES: `${USERS_BASE}/lendflow`,
    NOTIFICATIONS: `${USERS_BASE}/%s/notifications`,
    COLUMN_PREFERENCES: `api/int/my/preferences`,
    FILTER_BY_ROLE: `${USERS_BASE}/filter_by_role`,
    RESET_RING_CENTRAL: `${USERS_BASE}/%s/reset-ring-central`,
    EMAIL_ADDRESSES: {
      ALL: `${USERS_BASE}/%s/email_addresses`,
      UPLOAD_IMAGE: `${USERS_BASE}/%s/email_addresses/upload_image`
    }
  },

  WORKFLOW_TEMPLATES: {
    ALL: `${WORKFLOW_BASE}`,
    FILTER: "/api/int/workflows/filter",
    FILTER_TEMPLATES: "/api/int/filter_workflow_templates",
    SINGLE: `${WORKFLOW_BASE}/%s`,
    TOGGLE_GLOBAL: `${WORKFLOW_BASE}/%s/toggle_global_status`,
    SUBMIT_TO_NEXT_STAGE: `${APPLICATIONS_BASE}/%s/workflow/stages/next`,
    ROLLBACK_STAGE_PROGRESS: `${APPLICATIONS_BASE}/%s/workflow/stages/rollback`,
    WORKFLOW_SNAPSHOT: `${APPLICATIONS_BASE}/%s/workflow`,
    WORKFLOW_STATUS: `${APPLICATIONS_BASE}/%s/workflow_status`,
    WORKFLOW_DISCOVERY_QUESTIONS: `${APPLICATIONS_BASE}/%s/workflow/discovery_questions`,
    WORKFLOW_DISCOVERY_ANSWERS: `${APPLICATIONS_BASE}/%s/workflow/discovery_questions/answer`,
    WORKFLOW_FIELDS_DATA: `${APPLICATIONS_BASE}/%s/workflow/stages/application/fields`,
    UPDATE_APP: `${APPLICATIONS_BASE}/%s/workflow`,
    DUPLICATE_TEMPLATE: `${WORKFLOW_BASE}/%s/duplicate`
  },

  EMAIL_TEMPLATES: {
    ALL: `${EMAIL_TEMPLATES_BASE}`,
    SINGLE: `${EMAIL_TEMPLATES_BASE}/%s`,
    DUPLICATE: `${EMAIL_TEMPLATES_BASE}/%s/duplicate`,
    TEST_DATA: `${EMAIL_TEMPLATES_BASE}/test_data`,
    TEST_SEND: `${EMAIL_TEMPLATES_BASE}/test_send`
  },

  SMS_TEMPLATES: {
    ALL: `${SMS_TEMPLATES_BASE}`,
    SINGLE: `${SMS_TEMPLATES_BASE}/%s`,
    DUPLICATE: `${SMS_TEMPLATES_BASE}/%s/duplicate`,
    TEST_DATA: `${SMS_TEMPLATES_BASE}/test_data`,
    TEST_SEND: `${SMS_TEMPLATES_BASE}/test_send`
  },

  STIPS: {
    ALL: "/api/int/stips",
    SINGLE: "/api/int/stips/%s"
  },

  DATA_ORCHESTRATION_TEMPLATES: {
    ALL: `${DATA_ORCHESTRATION_BASE}`,
    SINGLE: `${DATA_ORCHESTRATION_BASE}/%s`,
    SERVICES: `${DATA_ORCHESTRATION_BASE}/services`
  },

  PRESETS: {
    ALL: PRESETS_BASE,
    SINGLE: `${PRESETS_BASE}/%s`
  },

  SCORECARDS: {
    ALL: `${SCORECARDS_BASE}`,
    BACKTEST_REPORTS: {
      ALL: `${BASE}/score_card_backtest_report`,
      SINGLE: `${BASE}/score_card_backtest_report/%s`
    },
    DUPLICATE: `${SCORECARDS_BASE}/%s/duplicate`,
    MULTI_RESULTS: `${SCORECARDS_BASE}/%s/multi-results`,
    PRIMARY: `${APPLICATIONS_BASE}/%s/score_cards/%s/primary`,
    RESULTS: `${SCORECARDS_BASE}/%s/results`,
    RUN_BACKTEST_REPORT: `${SCORECARDS_BASE}/%s/backtest_report`,
    RUN_SCORECARDS: `${APPLICATIONS_BASE}/%s/run_score_cards`,
    SERVICES: `${SCORECARDS_BASE}/services`,
    SINGLE: `${SCORECARDS_BASE}/%s`,
    UPDATE_POINTS: `${APPLICATIONS_BASE}/%s/score_cards/%s/points`
  },

  SCORECARD_GROUPS: {
    ALL: `${SCORECARD_GROUPS_BASE}`,
    BACKTEST_REPORT: `${SCORECARD_GROUPS_BASE}/%s/backtest_report`,
    MULTI_RESULTS: `${SCORECARD_GROUPS_BASE}/%s/multi-results`,
    PRIMARY: `${APPLICATIONS_BASE}/%s/score_card_groups/%s/primary`,
    RUN_SCORECARD_GROUPS: `${APPLICATIONS_BASE}/%s/run_score_card_groups`,
    SINGLE: `${SCORECARD_GROUPS_BASE}/%s`,
    RESULTS: `${SCORECARD_GROUPS_BASE}/%s/results`
  },

  SUBMISSION_PARTNER: {
    ALL: `${SUBMISSION_PARTNER_BASE}`
  },

  COMMUNICATION: {
    CALL_OUT: `${APPLICATIONS_BASE}/%s/call_out`,
    APP_LOGGING: `${APPLICATIONS_BASE}/%s/communications`,
    LOGS: {
      ALL: COMMUNICATION_LOGS_BASE,
      ALL_INDEXED: `${COMMUNICATION_LOGS_BASE}/filter`,
      SINGLE: `${COMMUNICATION_LOGS_BASE}/%s`
    },
    TASKS: {
      CREATE: `${BASE}/tasks`,
      UPDATE: `${BASE}/tasks/%s`
    },
    CALL_LOGS: {
      UPDATE: `${BASE}/call_sessions/%s`
    },
    COMMENTS: {
      /**
       * Placeholder 1 = "call_sessions" OR "tasks"
       * Placeholder 2 = "call_sessions" OR "tasks" ID
       * Placeholder 3 (Optional) = Comment ID
       */
      ALL: `${BASE}/%s/%s/comments`,
      SINGLE: `${BASE}/%s/%s/comments/%s`
    },
    EMAILS: {
      SEND: EMAIL_BASE,
      MODAL_DATA: `${EMAIL_BASE}/modal_data`,
      ASSIGN_TO_APPLICATION: `${EMAIL_BASE}/%s/assign`,
      THREADS: `${EMAIL_BASE}/threads`,
      THREAD: `${EMAIL_BASE}/threads/%s`,
      REPLY: `${EMAIL_BASE}/%s/reply`,
      RECIPIENTS: `${EMAIL_BASE}/recipients`
    },
    SMS: {
      ASSIGN_TO_APPLICATION: `${SMS_BASE}/%s/assign`,
      REPLY: `${SMS_BASE}/%s/reply`,
      MODAL_DATA: `${SMS_BASE}/modal_data`,
      SEND: `${SMS_BASE}`
    },
    PHONES: {
      BLOCK: `${COMMUNICATION_BASE}/%s/block-number`,
      UNBLOCK: `${COMMUNICATION_BASE}/%s/unblock-number`
    }
  },

  BATCH_ACTIONS: {
    DEAL_MODE: `${BATCH_ACTIONS_BASE}/applications/deal_mode`,
    ROLES: `${BATCH_ACTIONS_BASE}/applications/roles`
  },

  LENDER_NETWORKS: {
    INDEX: "/api/int/lender_networks",
    SHOW: "/api/int/lender_networks/%s"
  },

  MY: {
    ORGANIZATION_USERS: `${MY_BASE}/organization/users`
  },

  NOTES: {
    ALL: `${BASE}/notes`,
    SINGLE: `${BASE}/notes/%s`,
    MARK_AS_READ: `${BASE}/notes/mark_as_read`
  },

  CONSENT_TEMPLATE: {
    ALL: `${BASE}/consent_templates`,
    SINGLE: `${BASE}/consent_templates/%s`
  },

  STATISTIC: "/api/int/account/statistic",
  ANALYTICS: "/api/int/operations/analytics",
  SYSTEM_PREFERENCES: "api/int/system_preferences",
  DEAL_VISIBILITY: "api/int/system_preferences/deal_visibility",
  WEBSOCKET_AUTH: "/broadcasting/auth",
  KEY_VALUE: "/api/int/kv"
};

const endpointsRequiringCSRFToken = [
  endpoints.LOGIN,
  endpoints.REGISTER,
  endpoints.SEND_RESET_LINK,
  endpoints.RESET_PASSWORD,
  endpoints.RENEW_PASSWORD
];

export const requiresCSRFToken = (endpoint: string): boolean =>
  endpointsRequiringCSRFToken.includes(endpoint);

export default endpoints;
